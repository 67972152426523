import React, { useContext } from 'react';
import { Box, Typography, Container, Button, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { LanguageContext, translations } from '../utils/languageContext';

const TechBackground = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      zIndex: 0,
    }}
  >
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
          <path d="M 40 0 L 0 0 0 40" fill="none" stroke="currentColor" strokeWidth="0.5" strokeOpacity="0.1" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#grid)" />
      <motion.circle
        cx="75%"
        cy="60%"
        r="300"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeOpacity="0.05"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 10, repeat: Infinity, repeatType: 'reverse', ease: 'easeInOut' }}
      />
      <motion.path
        d="M 100 100 L 300 100 L 200 300 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeOpacity="0.05"
        initial={{ scale: 0.9, opacity: 0, rotate: 0 }}
        animate={{ scale: 1, opacity: 1, rotate: 360 }}
        transition={{ duration: 20, repeat: Infinity, repeatType: 'loop', ease: 'linear' }}
      />
    </svg>
  </Box>
);

export const LandingBlock: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.05,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <TechBackground />
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={itemVariants}>
            <Typography 
              variant="h2" 
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2.75rem', sm: '3rem', md: '3.5rem' },
                maxWidth: '1000px',
                // background: 'linear-gradient(45deg, #000, #333)',
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
              }}
            >
              {t.title}
            </Typography>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Typography 
              variant="h4"
              sx={{
                fontWeight: 300,
                fontSize: { xs: '1.4rem', sm: '1.5rem', md: '1.5rem' },
                mb: 4,
                maxWidth: '800px',
                color: theme.palette.text.secondary,
              }}
            >
              {t.subtitle}
            </Typography>
          </motion.div>
          {/* <motion.div variants={itemVariants}>
            <Button 
              variant="outlined" 
              size="large"
              sx={{
                borderColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
                textTransform: 'none',
              }}
            >
              Get Started
            </Button>
          </motion.div> */}
        </motion.div>
      </Container>
    </Box>
  );
};