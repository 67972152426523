import React, { useContext } from 'react';
import { Box, Typography, Container, Grid, Divider, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { LandingBlock } from '../components/LandingBlock';
import { CodeCell } from '../components/CodeCell';
import { StackDiagram } from '../components/StackDiagram';
import { ModelFeatures } from '../components/Features';
import { LanguageContext, translations } from '../utils/languageContext';

export const LandingPage: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Box>
      <LandingBlock />
      <Container maxWidth="lg" sx={{ my: { xs: 8, md: 16 } }}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <motion.div variants={itemVariants}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                  {t.boostResults}
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.2rem', fontWeight: 300, color: 'text.secondary', mb: 0 }}>
                  {t.addReranker}
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div variants={itemVariants}>
                <CodeCell />
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
      <Box sx={{ py: { xs: 8, md: 16 }, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <ModelFeatures/>
        </Container>
      </Box>
      {/* <Divider light/> */}
      <Box sx={{ py: { xs: 8, md: 16 }, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <StackDiagram />
          </motion.div>
        </Container>
      </Box>
      <Box sx={{ py: { xs: 10, md: 10 }, bgcolor: theme.palette.background.default }}>
        <Container sx={{display: 'flex', justifyContent: 'center'}}>
          <Typography gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
            {t.tryTimeDescription}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};