import React, { useState, useContext } from 'react';
import { Paper, Tabs, Tab, Box, Typography, useTheme } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { codeSnippets } from '../utils/codeSnippets';
import { LanguageContext, translations } from '../utils/languageContext';
import { motion, AnimatePresence } from 'framer-motion';

export const CodeCell: React.FC = () => {
  const [language, setLanguage] = useState<'python' | 'javascript' | 'curl'>('python');
  const { language: appLanguage } = useContext(LanguageContext);
  const t = translations[appLanguage];
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: 'python' | 'javascript' | 'curl') => {
    setLanguage(newValue);
  };

  const tabStyle = {
    textTransform: 'none',
    // fontWeight: 600,
    // fontSize: '0.9rem',
    minWidth: 100,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        overflow: 'hidden', 
        borderRadius: 2,
        // boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: theme.palette.background.paper }}>
        <Tabs 
          value={language} 
          onChange={handleChange} 
          aria-label="code language tabs"
          TabIndicatorProps={{
            style: {
              height: 3,
              borderRadius: '3px 3px 0 0',
            }
          }}
        >
          <Tab label="Python" value="python" sx={tabStyle} />
          <Tab label="JavaScript" value="javascript" sx={tabStyle} />
          <Tab label="Shell" value="curl" sx={tabStyle} />
        </Tabs>
      </Box>
      <Box sx={{ p: 3, bgcolor: '#1e1e1e' }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={language}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <Typography variant="caption" sx={{ color: '#6a737d', display: 'block' }}>
              {t.codeExample}
            </Typography>
            <SyntaxHighlighter 
              language={language} 
              style={atomDark} 
              customStyle={{ 
                fontSize: '0.9rem',
                borderRadius: theme.shape.borderRadius,
                padding: '10px',
              }}
            >
              {codeSnippets[language]}
            </SyntaxHighlighter>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Paper>
  );
};