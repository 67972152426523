export const codeSnippets = {
    python: `import requests
import json

url = "https://api.synarch.ai/sy-rerank"
headers = {
    "Content-Type": "application/json"
}
payload = {
    "query": "best practices in RAG implementation",
    "documents": [
        {"id": "1", "text": "An introduction to Retrieval-Augmented Generation (RAG)..."},
        {"id": "2", "text": "The best open source models for RAG in 2024 are..."},
        {"id": "3", "text": "Advice for implementing Retrieval-Augmented Generation..."},
    ]
}

response = requests.post(url, headers=headers, data=json.dumps(payload))`,
    javascript: `const axios = require('axios');
  
const url = 'https://api.synarch.ai/sy-rerank';
const headers = {
  "Content-Type": "application/json"
};
const payload = {
  query: 'best practices in RAG implementation',
  documents: [
    { id: '1', text: 'An introduction to Retrieval-Augmented Generation (RAG)...' },
    { id: '2', text: 'The best open source models for RAG in 2024 are...' },
    { id: '3', text: 'Advice for implementing Retrieval-Augmented Generation...' }
  ]
};
  
axios.post(url, payload, { headers: headers })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error('Error:', error.response ? error.response.data : error.message);
  });`,
    curl: `curl -X POST https://api.synarch.ai/sy-rerank \
-H "Content-Type: application/json" \
-d '{
  "query": "best practices in RAG implementation",
  "documents": [
    {"id": "1", "text": "An introduction to Retrieval-Augmented Generation (RAG)..."},
    {"id": "2", "text": "The best open source models for RAG in 2024 are..."},
    {"id": "3", "text": "Advice for implementing Retrieval-Augmented Generation..."}
  ]
}'`
  };