import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './theme/theme';
import { AppBar } from './components/AppBar';
import { LandingPage } from './pages/LandingPage';
import { LanguageContext, Language } from './utils/languageContext';

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState<Language>('en');

  useEffect(() => {
    const browserLang = navigator.language.split('-')[0] as Language;
    if (['en', 'fr', 'de'].includes(browserLang)) {
      setLanguage(browserLang);
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <CssBaseline />
        <AppBar toggleTheme={toggleTheme} />
        <LandingPage />
      </LanguageContext.Provider>
    </ThemeProvider>
  );
};

export default App;