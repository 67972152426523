import React, { useContext } from 'react';
import { AppBar as MuiAppBar, Toolbar, Typography, Button, IconButton, useTheme, Box, Select, MenuItem } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { LanguageContext, languages, translations } from '../utils/languageContext';

interface AppBarProps {
  toggleTheme: () => void;
}

export const AppBar: React.FC<AppBarProps> = ({ toggleTheme }) => {
  const theme = useTheme();
  const { language, setLanguage } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <MuiAppBar position="fixed" elevation={0}>
      <Toolbar>
        <Typography component="div" sx={{ fontSize: '21px', flexGrow: 1 }}>
          Synarch
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {/* <Button color="inherit">{t.features}</Button>
          <Button color="inherit">{t.pricing}</Button> */}
          {/* <Button color="inherit">{t.docs}</Button>
          <Button color="inherit">{t.contact}</Button> */}
        </Box>
        <Select
          size="small"
          value={language}
          onChange={(e) => setLanguage(e.target.value as any)}
          sx={{
            height: '32px',
            maxWidth: '80px',
            marginLeft: '10px',
            '.MuiSelect-select': {
              padding: '0px 16px',
            },
            '.MuiOutlinedInput-root': {
              paddingRight: '24px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: 10,
            },
          }}
        >
          {Object.entries(languages).map(([code, name]) => (
            <MenuItem key={code} value={code}>{name}</MenuItem>
          ))}
        </Select>
        <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};