import React from 'react';

export type Language = 'en' | 'fr'// | 'de';

export const languages: Record<Language, string> = {
  en: 'en',
  fr: 'fr',
  // de: 'de',
};

export const translations: Record<Language, Record<string, string>> = {
  en: {
    features: 'Features',
    pricing: 'Pricing',
    docs: 'Docs',
    contact: 'Contact',
    getStarted: 'Get Started',
    learnMore: 'Learn More',
    title: 'Boost your search results',
    subtitle: 'Sy-rerank is the magic layer on top of your existing search to boost retrieval performance.',
    boostResults: 'The magic layer',
    addReranker: 'Our reranker model is designed for companies and RAG app builders to improve the relevance of your retrieval, with just a few lines of code.',
    stackTitle: 'Where we fit in your stack',
    stackDes: 'Sy-rerank seamlessly integrates into the modern AI stack, working sequentially with other tools.',
    yourApp: 'Query',
    databaseRetrieval: 'Database / API search',
    reranker: 'Results reranking with Sy-rerank',
    results: 'Generation, matching, display...',
    modelFeaturesTitle: 'Model features',
    docLength: 'Unlimited document length',
    docLengthDescription: 'Rerank documents of any length.',
    numDocs: 'Rerank numerous documents',
    numDocsDescription: 'Send an unlimited number of documents to the API.',
    supportedLanguages: 'Universal language support',
    supportedLanguagesDescription: 'Sy-rerank supports all languages.',
    responseTime: 'Lightning-fast response',
    responseTimeDescription: 'Receive responses in one second.',
    try: 'Try!',
    tryTimeDescription: 'Try sy-rerank simply by copying-and-pasting the code above.',
  },
  fr: {
    features: 'Fonctionnalités',
    pricing: 'Tarifs',
    docs: 'Documentation',
    contact: 'Contact',
    getStarted: 'Commencer',
    learnMore: 'En savoir plus',
    title: 'Boostez vos résultats de recherche',
    subtitle: "Sy-rerank est la couche magique au-dessus de votre recherche de données pour améliorer la pertinence des résultats.",
    boostResults: 'La couche magique',
    addReranker: "Notre modèle de reclassement est conçu pour les entreprises et les développeurs d'applications RAG pour améliorer la pertinence de vos recherches, en juste quelques lignes de code.",
    stackTitle: 'Notre place dans votre stack',
    stackDes: "Sy-rerank s'intègre parfaitement dans le stack IA moderne, fonctionnant séquentiellement avec d'autres outils.",
    yourApp: 'Requête',
    databaseRetrieval: 'Recherche database / API',
    reranker: 'Reclassement des résultats avec Sy-rerank',
    results: 'Génération, matching...',
    modelFeaturesTitle: 'Fonctionnalités',
    docLength: 'Longueur de document illimitée',
    docLengthDescription: 'Reclassez des documents de toute taille.',
    numDocs: 'Reclassez de nombreux documents',
    numDocsDescription: "Envoyez un nombre illimité de documents à l'API.",
    supportedLanguages: 'Support universel des langues',
    supportedLanguagesDescription: 'Sy-rerank supporte toutes les langues.',
    responseTime: 'Réponses ultra-rapides',
    responseTimeDescription: "Recevez des réponses en une seconde.",  
    try: 'Try!',
    tryTimeDescription: 'Essayez sy-rerank simplement en copiant-collant le code plus haut.',  
  },
  // de: {
  //   "features": "Funktionen",
  //   "pricing": "Preise",
  //   "docs": "Dokumentation",
  //   "contact": "Kontakt",
  //   "getStarted": "Erste Schritte",
  //   "learnMore": "Mehr erfahren",
  //   "title": "Steigern Sie Ihre Abrufresultate",
  //   "subtitle": "Die magische Schicht über Ihrer bestehenden Datenbanksuche, um die Abrufrelevanz zu steigern.",
  //   "boostResults": "Die magische Schicht",
  //   "addReranker": "Unser Reranker-Modell ist für Unternehmen und RAG-App-Entwickler konzipiert, um die Relevanz ihrer bestehenden Datenbankabfragen zu verbessern, die die Relevanz mit nur wenigen Codezeilen steigert.",
  //   "stackTitle": "Wo wir in Ihrem Stack passen",
  //   "stackDes": "Phi-rerank integriert sich nahtlos in den modernen KI-Stack und arbeitet nacheinander mit anderen Tools.",
  //   "yourApp": "Benutzeranfrage",
  //   "databaseRetrieval": "Datenbankabfrage / API",
  //   "reranker": "Ergebnis-Neuordnung mit Sy-rerank",
  //   "results": "Generierung, Abgleich..."
  // },
};

export const LanguageContext = React.createContext<{
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
}>({
  language: 'en',
  setLanguage: () => {},
});