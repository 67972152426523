import { createTheme, ThemeOptions } from '@mui/material/styles';

const baseTheme = createTheme();

const getTheme = (mode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode,
    primary: {
        main: mode === 'light' ? '#111111' : '#fafafa',
    },
    secondary: {
      main: mode === 'light' ? '#111111' : '#fafafa',
    },
    background: {
      default: mode === 'light' ? '#ffffff' : '#090909',
      paper: mode === 'light' ? '#fafafa' : '#111111',
    },
    text: {
        primary: mode === 'light' ? '#000000' : '#ffffff',
        secondary: mode === 'light' ? '#000000' : '#ffffff',
      },
  },
  typography: {
    fontFamily: '"Roboto Mono", monospace, "Georgia", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  components: {
    MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(9, 9, 9, 0.9)',
            backdropFilter: 'blur(10px)',
            border: `1px solid ${mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'}`,
            color: mode === 'light' ? '#000000' : '#ffffff',
            height: '55px',
            margin: '15px',
            borderRadius: '10px',
            width: 'calc(100% - 30px)',
            left: '0',
            right: '0',
            top: '0',
            justifyContent: 'center',
            [baseTheme.breakpoints.down('sm')]: {
              margin: '10px',
              width: 'calc(100% - 20px)',
            },
          },
        },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'inherit',
        },
      },
    },
    MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(12, 12, 12, 0.8)',
            border: `1px solid ${mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'}`,
            color: mode === 'light' ? '#000000' : '#ffffff',
            backdropFilter: 'blur(10px)',
            borderRadius: 8
          },
        },
      },
  },
});

export const lightTheme = createTheme(getTheme('light'));
export const darkTheme = createTheme(getTheme('dark'));