import React, { useContext } from 'react';
import { Box, Typography, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { LanguageContext, translations } from '../utils/languageContext';
import { motion } from 'framer-motion';

export const StackDiagram: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const renderArrow = (direction: 'horizontal' | 'vertical') => (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        mx: { xs: direction === 'vertical' ? 0 : 0, md: direction === 'horizontal' ? 2 : 0 },
        my: { xs: direction === 'vertical' ? 0 : 0, md: direction === 'horizontal' ? 0 : 2 },
      }}
    >
      <motion.svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        animate={{ x: direction === 'horizontal' ? [0, 5, 0] : 0, y: direction === 'vertical' ? [0, 5, 0] : 0 }}
        transition={{ repeat: Infinity, duration: 2.5, ease: "easeInOut" }}
      >
        {direction === 'horizontal' ? (
          <path
            d="M5 20H35M35 20L28 13M35 20L28 27"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : (
          <path
            d="M20 5V35M20 35L13 28M20 35L27 28"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </motion.svg>
    </Box>
  );

  const stackItems = [
    { title: t.yourApp, code: '<App />' },
    { title: t.databaseRetrieval, code: 'db.query()' },
    { title: t.reranker, code: 'rerank()' },
    { title: t.results, code: 'results[]' },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 0 }}>
      <Box sx={{ mb: 7, maxWidth: '800px' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', letterSpacing: '-0.5px', mb: 2 }}>
          {t.stackTitle}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 300, color: 'text.secondary' }}>
          {t.stackDes}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 1, md: 2 },
        }}
      >
        {stackItems.map((item, index) => (
          <React.Fragment key={item.title}>
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
              style={{ width: '100%' }}
            >
              <Paper
                elevation={0}
                sx={{
                  height: '120px',
                  p: 2,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    borderColor: 'primary.main',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: '"Roboto Mono", monospace',
                    color: 'text.secondary',
                    fontWeight: index === 2 ? 'bold' : 'normal',
                  }}
                >
                  {item.title}
                </Typography>
              </Paper>
            </motion.div>
            {index < 3 && renderArrow(isMobile ? 'vertical' : 'horizontal')}
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
};