import React, { useContext } from 'react';
import { Box, Typography, Container, Grid, Paper, useTheme } from '@mui/material';
import { LanguageContext, translations } from '../utils/languageContext';
import { motion } from 'framer-motion';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';

export const ModelFeatures: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const theme = useTheme();

  const features = [
    { icon: StorageRoundedIcon, title: t.numDocs, description: t.numDocsDescription },
    { icon: FeedOutlinedIcon, title: t.docLength, description: t.docLengthDescription },
    { icon: LanguageOutlinedIcon, title: t.supportedLanguages, description: t.supportedLanguagesDescription },
    { icon: BoltRoundedIcon, title: t.responseTime, description: t.responseTimeDescription },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 0 }}>
      <Box sx={{ mb: 6, maxWidth: '800px' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
          {t.modelFeaturesTitle}
        </Typography>
        {/* <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 300, color: 'text.secondary' }}>
          {t.modelFeaturesDescription}
        </Typography> */}
      </Box>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} key={feature.title}>
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
            >
              <Paper
                elevation={0}
                sx={{
                  height: '100%',
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  border: '2px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    borderColor: 'primary.main',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {React.createElement(feature.icon, { 
                    sx: { 
                    mr: 1.5,
                    color: theme.palette.primary.main 
                    } 
                })}
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{feature.title}</Typography>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', flex: 1 }}>
                  {feature.description}
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ModelFeatures;